import { MIN_PASSWORD_LENGTH } from '@/constants/auth';

export const isNotEmpty = <V>(value: V) => !!value && !(/^\s*$/.test(String(value)));

export const isDuration = <V>(value: V) => !!value
  && ((/(?:(\d{3}):([0-5]\d):)([0-5]\d)/i).test(String(value)))
  && String(value) !== '000:00:00';

export const isEmail = <V>(value: V) => {
  const regExp = new RegExp(/^[^\s]+@[^\s]+\.[^\s]+$/);

  return regExp.test(String(value));
};

export const isIBAN = <V>(value: V) => {
  const regExp = new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,35}\s?$/);

  return regExp.test(String(value));
};

export const isLink = <V>(value: V) => {
  const regExp = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._s+~#=]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);

  return regExp.test(String(value));
};

export const isLinkIfNotEmpty = <V>(value: V) => {
  const regExp = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._s+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);

  return regExp.test(String(value)) || !value;
};

export const isYouTubeVideoLink = <V>(value: V) => {
  const regExp = new RegExp(/^(?:http(s)?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=([-a-zA-Z0-9@:%._s+~#=]+)|youtu\.be\/([-a-zA-Z0-9@:%._s+~#=]+))(?:&.*)?/);

  return regExp.test(String(value)) || !value;
};

export const isVimeoVideoLink = <V>(value: V) => {
  const regExp = new RegExp(/(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/);

  return regExp.test(String(value)) || !value;
};

export const isVideoLink = <V>(value: V) => (
  isYouTubeVideoLink(value) || isVimeoVideoLink(value)
);

export const isNotVideoLink = <V>(value: V) => (
  (isLink(value) && !isVideoLink(value)) || !value
);

export const isTelegramProfileLink = <V>(value: V) => {
  const regExp = new RegExp(/^(?:http(s)?:\/\/)?(www\.)?(?:t\.me\/([a-zA-Z0-9_]+))(?:&.*)?/);

  return regExp.test(String(value)) || !value;
};

export const isTelegramProfileNickname = <V>(value: V) => {
  const regExp = new RegExp(/^@[a-zA-Z0-9_-]{1,30}$/);

  return regExp.test(String(value)) || !value;
};

export const isValidUserAge = <V>(value: V) => {
  const regExp = new RegExp(/^[0-9]+$/);

  return regExp.test(String(value)) || !value;
};

export const isLinkedInProfileLink = <V>(value: V) => {
  const regExp = new RegExp(/^(?:http(s)?:\/\/)?(www\.)?(?:linkedin\.com\/in\/([a-zA-Z0-9_-]{3,}))(?:&.*)?/);

  return regExp.test(String(value)) || !value;
};

export const isValidPasswordContent = <V>(value: V) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d).+$/;

  const str = String(value) || '';

  return regex.test(str);
};

export const isValidPasswordLength = <V>(value: V) => {
  const str = String(value) || '';

  return str.length >= MIN_PASSWORD_LENGTH;
};

export const minLength = (min: number) => <V>(value: V) => (
  String(value).trim().length >= min
);

export const maxLength = (max: number) => <V>(value: V) => (
  String(value).trim().length <= max
);

export const minValue = (min: number) => <V>(value: V) => Number(value) >= min;

export const maxValue = (max: number) => <V>(value: V) => Number(value) <= max;

export const inRange = (min: number, max: number) => <V>(value: V) => (
  minValue(min)(value) && maxValue(max)(value)
);

export const minArrayValue = (min: number) => (
  <V>(value: V) => {
    if (value instanceof Array) {
      return (minValue(min))(value.length);
    }

    return true;
  }
);

export const maxArrayValue = (max: number) => (
  <V>(value: V) => {
    if (value instanceof Array) {
      return (maxValue(max))(value.length);
    }

    return true;
  }
);

export const hasNoSpaces = <V>(value: V) => {
  const regExp = new RegExp(/^\S+$/);

  return regExp.test(String(value));
};

export const isSlugValid = <V>(value: V) => {
  const regExp = new RegExp(/^[a-zA-Z_-\d]+$/);

  return regExp.test(String(value)) || !value;
};

export const isImageUrlAndNoEmpty = <V>(value: V) => {
  const regExp = new RegExp(/(https?:\/\/.*\.(?:png|jpg|jpeg|svg))/i);

  return regExp.test(String(value)) || !value;
};

export const isValidForFileName = <V>(value: V) => {
  const regExp = new RegExp(/^[a-zA-Z_.\d/-]*$/);

  return regExp.test(String(value)) || !value;
};

export const hasOnlySpaces = <V>(value: V) => !(
  String(value).length && !String(value).trim().length
);

export const hasExtension = <V>(value: V) => {
  const regExp = new RegExp(/\.(\w+)/);

  return regExp.test(String(value)) || !value;
};

export const isArrayNotEmpty = <V>(value: V) => (
  Array.isArray(value) && value.length > 0
);

export const isAnyValidLink = <V>(value: V): boolean => {
  const urlRegex = /^(https?:\/\/)?(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,6})(:\d+)?(\/[^\s]*)?$/i;
  const emailRegex = /^(mailto:(\/\/)?)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const telRegex = /^tel:\+?\d{1,14}$/;
  const unicodeUrlRegex = /^(https?:\/\/)?(([\p{L}\d]([\p{L}\d-]*[\p{L}\d])?(\.[\p{L}\d]([\p{L}\d-]*[\p{L}\d])?)+)|(\[((?:[A-Fa-f\d:.]+))\]))(:\d+)?(\/[^\s]*)?$/iu;

  return [
    urlRegex,
    emailRegex,
    telRegex,
    unicodeUrlRegex,
  ].some((regex) => regex.test(String(value)));
};

export const isAnyValidLinkIfNotEmpty = <V>(value: V) => (
  isAnyValidLink(value) || !value
);

export const isPhoneNumber = <V>(value: V) => {
  const regExp = new RegExp(/^\+?[0-9]+$/);

  return regExp.test(String(value)) || !value;
};
