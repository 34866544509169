import React, {
  createContext, useContext,
  FC, SetStateAction, Dispatch, useState, useCallback,
} from 'react';
import { UserProfileMode, UserProfileOpener } from '@/components/platform/UserProfile/typedefs';
import { usePaywall } from '@/components/platform/SubscriptionProduct/hooks/usePaywallModal';
import { useIsSubscriptionLimitationEnabled } from '@/components/platform/SubscriptionProduct/hooks/useIsSubscriptionLimitationEnabled';
import { PaywallLocations } from '@/controllers/analytics/generated';

type UserProfileContextType = {
  userId: number | null;
  mode: UserProfileMode;
  setMode: Dispatch<SetStateAction<UserProfileMode>>;
  closeProfile: () => void;
  isPaywallOpened: boolean;
  closePaywall: () => void;
  openPaywall: () => void;
  shouldUseDMsPaywall: boolean;
  opener: UserProfileOpener;
  openProfile: (options: {
    userId: number;
    opener: UserProfileOpener;
  }) => void;
};

type UserProfileContextHook = () => UserProfileContextType;

const UserProfileContext = createContext<
  UserProfileContextType
>({
  userId: null,
  mode: UserProfileMode?.DefaultView,
  setMode: () => { /* empty */ },
  closeProfile: () => { /* empty */ },
  isPaywallOpened: false,
  closePaywall: () => { /* empty */ },
  openPaywall: () => { /* empty */ },
  shouldUseDMsPaywall: false,
  opener: UserProfileOpener?.Unspecified,
  openProfile: () => { /* empty */ },
});

export const UserProfileContextProvider: FC<
  Partial<UserProfileContextType>
> = ({
  children,
  ...values
}) => {
  const [userId, setUserId] = useState<number | null>(values.userId ?? null);
  const [mode, setMode] = useState<UserProfileMode>(
    UserProfileMode?.DefaultView,
  );
  const [opener, setOpener] = useState<UserProfileOpener>(
    UserProfileOpener?.Unspecified,
  );
  const shouldUseDMsPaywall = useIsSubscriptionLimitationEnabled();
  const {
    isPaywallOpened,
    closePaywall,
    openPaywall,
  } = usePaywall({
    paywallLocation: PaywallLocations.UserProfile,
  });

  const closeProfile = useCallback(() => {
    setUserId(null);
    setMode(UserProfileMode?.DefaultView);
    setOpener(UserProfileOpener?.Unspecified);
  }, []);

  const openProfile = useCallback((options: {
    userId: number;
    opener: UserProfileOpener;
  }) => {
    setUserId(options.userId);
    setOpener(options.opener);
  }, []);

  return (
    <UserProfileContext.Provider
      value={{
        userId,
        mode,
        setMode,
        closeProfile,
        isPaywallOpened,
        closePaywall,
        openPaywall,
        shouldUseDMsPaywall,
        opener,
        openProfile,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfileContext: UserProfileContextHook = () => (
  useContext(UserProfileContext)
);
